import { PayloadAction, ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ITasksState } from '.';
import { api } from '../../api/api';

export interface ICheckJoinChannelResponse {
    taskId: number;
    subscribed: boolean;
};

export const checkJoinChannelsTasks = createAsyncThunk<ICheckJoinChannelResponse, number>(
    'checkJoinChannelsTasks',
    async (taskId: number) => await api.checkJoinChannelTasks(taskId)
);

const checkJoinTasksHandler = (builder: ActionReducerMapBuilder<ITasksState>) => {
    builder
        .addCase(checkJoinChannelsTasks.pending, (state: ITasksState) => {
            state.loading = true;
            state.error = "";
        })
        .addCase(checkJoinChannelsTasks.fulfilled, (state: ITasksState, action: PayloadAction<ICheckJoinChannelResponse>) => {
            state.loading = false;

            if (!action.payload) {
                state.error = 'Unable to check join channel task';
                return;
            }

            state.joinChannelTasks.forEach(task => {
                if (task.taskId === action.payload.taskId) {
                    task.subscribed = action.payload.subscribed;

                    if (!task.subscribed)
                        state.error = "User isn't channel member";
                }
            });
        })
        .addCase(checkJoinChannelsTasks.rejected, (state: ITasksState) => {
            state.loading = false;
            state.error = 'Unable to check join channel task';
        });
};

export default checkJoinTasksHandler;