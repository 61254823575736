import { ButtonBlock } from "../../components/ButtonBlock/ButtonBlock";
import { FC } from "react";
import { Divider, useTheme } from "@mui/material";
import { useNavigate } from "../../hooks/navigate";
import { AppContext } from "../../components/BottomMenu/BottomMenu";

interface IDetailTasksPageProps {
    children: JSX.Element;
}

export const DetailTasksPage: FC<IDetailTasksPageProps> = ({ children }) => {
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <>
            <ButtonBlock
                caption={ 'Назад к списку заданий' }
                handler={ () => navigate(AppContext.Tasks) }
            />

            <Divider
                sx={{
                    padding: '4pt',
                    color: theme.palette.primary.main,
                }}
            />

            { children }
        </>
    );
}