import React, { FC, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

interface IErrorSnackbarProps {
    errorMessage: string;
}

export const ErrorSnackbar: FC<IErrorSnackbarProps> = ({ errorMessage }) => {
    const [open, setOpen] = useState(!!errorMessage);

    const handleClose = (_event: React.SyntheticEvent<any> | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setOpen(false);
    };

    return (
        <div>
            <Snackbar open={ open } autoHideDuration={ 6000 } onClose={ handleClose } anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={ handleClose } severity="error" sx={{ width: '100%' }}>
                    { errorMessage }
                </Alert>
            </Snackbar>
        </div>
    );
};
