import { Typography, useTheme } from '@mui/material';
import { useSelector } from "../../hooks";

export const GameLevel = () => {
    const theme = useTheme();
    const level = useSelector(store => store.user.info.level);

    return (
        <Typography
            variant="body1"
            component="div"
            style={{
                border: '1px solid',
                borderRadius: '5pt',
                color: theme.palette.primary.main,
                fontWeight: 'bold',
                textAlign: 'center',
                padding: theme.spacing(1),
                height: '34.5pt',
                marginBottom: '5pt'
            }}
            >
            { `Уровень: ${level}` }
        </Typography>
    );
};