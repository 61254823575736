import { Stack, Typography, useTheme } from "@mui/material";
import { ReferalLinkBlock } from './ReferalLinkBlock';
import { ReferalsList } from './ReferalsList';

export const InviteTask = () => {
    const theme = useTheme();
    
    return (
        <Stack direction='column'>
            <Typography
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    padding: '4pt',
                    marginBottom: '4pt',
                    fontSize: '10pt',
                }}
            >
                Описание задания с указанием количества РУБЛ, получаемых за приглашение друга 
            </Typography>

            <ReferalLinkBlock />

            <Typography
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    padding: '4pt',
                    marginBottom: '4pt',
                    fontSize: '10pt',
                }}
            >
                Приглашенные друзья
            </Typography>

            <ReferalsList />
        </Stack>
    );
}