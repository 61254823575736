import { Typography, useTheme } from "@mui/material";
import { GeneralInfo } from "../../components/GeneralInfo/GeneralInfo";
import { DrawItem } from "./DrawItem";
import { FitSwitcherButton } from "./FitSwitcherButton";
import { DrawCountdown } from "./DrawCountdown";
import { useEffect } from "react";
import { useDispatch, useSelector } from "../../hooks";
import { getDraws } from "../../redux/draws/get-draws.handler";
import { Loader } from "../../components/Loader/Loader";
import { ErrorSnackbar } from "../../components/ErrorSnackbar/ErrorSnackbar";

export const DrawPage = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const { items: draws, loading, error } = useSelector(store => store.draws);

    useEffect(() => {
        dispatch(getDraws());
    }, [dispatch, getDraws]);

    return (
        <>
            <GeneralInfo />
            <FitSwitcherButton />
            <DrawCountdown />

            {
                draws?.length > 0 &&
                draws.map(draw => {
                    return <DrawItem
                        key={ `draw${draw.drawId}` }
                        draw={ draw }
                    />
                })
            }

            <Typography
                sx={{
                    margin: '10pt 0 10pt',
                    color: theme.palette.primary.main,
                }}
            >
                Описание розыгрыша
            </Typography>

            { loading && <Loader /> }

            { error.length > 0 && <ErrorSnackbar errorMessage={ error } /> }
        </>
    );
}