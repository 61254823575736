import { FC } from "react";
import { ISetModalAction, SET_MODAL } from "../actions/set-modal";

export interface IModalState<T> {
    modalComponent: FC | null;
    props: T | null;
}

const initState: IModalState<any> = {
    modalComponent: null,
    props: null
}

export const modalReducer = <T>(state: IModalState<T> = initState, action: ISetModalAction<T>): IModalState<T> => {
    switch (action.type) {
        case SET_MODAL:
            return { modalComponent: action.modalComponent, props: action.props };

        default:
            return state;
    }
}