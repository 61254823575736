import { useState } from "react";
import { ButtonBlock } from "../../components/ButtonBlock/ButtonBlock";

export const FitSwitcherButton = () => {
    const [forMeMode, setForMeMode] = useState(false);

    const handler = () => setForMeMode(!forMeMode);

    return (
        <ButtonBlock
            caption={ forMeMode ? 'Показать все' : 'Показать доступные мне' }
            handler={ handler }
        />
    );
}