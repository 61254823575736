import { useEffect } from "react";
import { GeneralInfo } from "../../components/GeneralInfo/GeneralInfo";
import { useDispatch, useSelector } from "../../hooks";
import { TasksRoutes } from "./TasksRoutes";
import { Loader } from "../../components/Loader/Loader";
import { JoinChannelTaskDto } from "./Tasks/JoinChannel/JoinChannelTask";
import { getTasks } from "../../redux/tasks/get-tasks.handler";
import { ErrorSnackbar } from "../../components/ErrorSnackbar/ErrorSnackbar";
import { tasksClearError } from "../../redux/tasks";

export class TasksDto {
    joinChannelTasks!: JoinChannelTaskDto[];
}

export const TasksPage = () => {
    const dispatch = useDispatch();
    const { joinChannelTasks, loading, error } = useSelector(store => store.tasks);

    useEffect(() => {
        if (error)
            dispatch(tasksClearError());
    }, [dispatch]); // не добавлять error
    
    useEffect(() => {
        if (joinChannelTasks.length > 0)
            return;

        dispatch(getTasks());
    }, [dispatch, joinChannelTasks]);
    
    return (
        <>
            <GeneralInfo />
            <TasksRoutes />

            { loading && <Loader /> }

            { error.length > 0 && <ErrorSnackbar errorMessage={ error } /> }
        </>
    );
}