import { Container, Typography, useTheme } from '@mui/material';
import { useSelector } from "../../hooks";


export const PassiveIncome = () => {
    const theme = useTheme();
    const profit = useSelector(store => store.user.info.profit);

    return (
        <Container
            style={{
                border: '1px solid',
                borderRadius: '5pt',
                color: theme.palette.primary.main,
                textAlign: 'center',
                padding: theme.spacing(1),
                height: '49pt',
            }}
        >
            <Typography
                variant="body1"
                component="div"
                style={{
                    fontWeight: 'bold',
                }}
            >
                { `Доход в час:` }
            </Typography>

            <Typography
                variant="body1"
                component="div"
                style={{
                    fontWeight: 'bold',
                }}
            >
                { `${profit} РУБЛ` }
            </Typography>
        </Container>
    );
};