import { Stack, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { Image } from "@mui/icons-material";

export interface IReferal {
    firstName: string;
    lastName: string;
    reward: number;
}

interface IReferalItemProps {
    referalData: IReferal;
}

export const ReferalItem: FC<IReferalItemProps> = ({ referalData }) => {
    const theme = useTheme();

    return (
        <Stack
            direction='row'
            sx={{
                color: theme.palette.primary.main,
            }}
        >
            <Image></Image>

            <Typography sx={{ width: '100%' }} >
                { `${referalData.firstName ?? ''} ${referalData.lastName ?? ''}`.trim() }
            </Typography>

            <Typography
                sx={{
                    width: '100pt',
                    textAlign: 'right',
                }}
            >
                { `${referalData.reward} РУБЛ` }
            </Typography>
        </Stack>
    );
}