import { Route, Routes } from 'react-router-dom';
import { JoinChannelTask } from './Tasks/JoinChannel/JoinChannelTask'; 
import { InviteTask } from './Tasks/InviteTask/InviteTask';
import { TaskContext, TasksTypes } from './TasksTypes';
import { DetailTasksPage } from './DetailTasksPage';
import { VkShareTask } from './Tasks/VkShareTask/VkShareTask';

export const TasksRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<TasksTypes />} />

            <Route path={ `/${TaskContext.JoinChannel}` } element={ <DetailTasksPage><JoinChannelTask /></DetailTasksPage> } />

            <Route path={ `/${TaskContext.VkShare}` } element={ <DetailTasksPage><VkShareTask /></DetailTasksPage> } />

            <Route path={ `/${TaskContext.Invite}` } element={ <DetailTasksPage><InviteTask /></DetailTasksPage> } />
        </Routes>
    );
};