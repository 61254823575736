import { trueHoursForm, trueMinutesForm2, trueSecondsForm2 } from "./true.word.form";

export const unixToShortString = (timestamp: number) => {
    if (!timestamp)
        return "";
    
    const date = new Date(timestamp * 1000);
    const dateTimeFormat = new Intl.DateTimeFormat('ru', { year: 'numeric', month: '2-digit', day: '2-digit' }) 
    const [{ value: day },,{ value: month },,{ value: year }] = dateTimeFormat.formatToParts(date) 
    
    return  day + "." + month + "." + year;
}

export const unixToString = (timestamp: number) => {
    if (!timestamp)
        return "";
    
        const date = new Date(timestamp * 1000);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
      
        return `${day}.${month} ${hours}:${minutes}`;
}

export const getTimeLeft = (operTime: number, sign: number = 1): string => {
    const currentTime = new Date().getTime() / 1000;
    const timeDiff = sign === 1 ? currentTime - operTime : operTime - currentTime;

    if (timeDiff < 60) {
        const seconds = Math.floor(timeDiff);
        return `${seconds} ${trueSecondsForm2(seconds)}`;
    } else if (timeDiff < 3600) {
        const minutes = Math.floor(timeDiff / 60);
        return `${minutes} ${trueMinutesForm2(minutes)}`;
    } else {
        const hours = Math.floor(timeDiff / 3600);
        return `${hours} ${trueHoursForm(hours)}`;
    }
}

export const now = () => Math.round(new Date().getTime() * 0.001);
