import { Stack } from "@mui/material";
import { TaskTypeBlock } from "./TaskTypeBlock";
import { useSelector } from "../../hooks";
import { trueFriendsForm } from "../../utils/true.word.form";
import { useEffect, useState } from "react";
import { VkshareTaskDto } from "./Tasks/VkShareTask/VkShareTask";
import { getTimeLeft, now } from "../../utils/time";

export enum TaskContext {
    JoinChannel = 'join-channel',
    VkShare = 'vk-share',
    Invite = 'invite',
}

export const TasksTypes = () => {
    const [friendsCount, setFriendsCount] = useState<number | undefined>(undefined);

    const { items: referals } = useSelector(store => store.user.referals);
    const { joinChannelTasks, vkShareTasks } = useSelector(store => store.tasks);

    useEffect(() => {
        const friendsCount = referals ? referals.length : undefined;
        setFriendsCount(friendsCount);
    }, [setFriendsCount, referals]);

    const getTelegramUnsubscribedChannelsCount = () => {
        const count = joinChannelTasks.reduce((acc, item) => {
            if (!item.subscribed)
                ++acc;
            return acc;
        }, 0);

        return count;
    }

    const getJoinChannelDescription = () => {
        const unsubscribed = getTelegramUnsubscribedChannelsCount();
        if (!joinChannelTasks.length || unsubscribed === 0)
            return 'Нет доступных';

        if (unsubscribed === joinChannelTasks.length)
            return `Доступно ${unsubscribed}`;

        return `Доступно ${unsubscribed} из ${joinChannelTasks.length}`;
    }

    const getVkShareTaskNeaerstDate = () => {
        const dates = vkShareTasks.map((task: VkshareTaskDto) => task.date);
        const nearetstDate = Math.min(...dates);

        return nearetstDate;
    }

    const getVkShareDescription = () => {
        if (!vkShareTasks.length)
            return 'Нет доступных';

        const nearetstDate = getVkShareTaskNeaerstDate();
        if (nearetstDate > now())
            return `Доступно через ${getTimeLeft(nearetstDate, -1)}`;

        const allowedCount = vkShareTasks.reduce((acc, item) => {
            if (item.date < now())
                ++acc;
            return acc;
        }, 0);

        if (allowedCount === vkShareTasks.length)
            return `Доступно ${allowedCount}`;

        return `Доступно ${allowedCount} из ${vkShareTasks.length}`;
    }

    return (
        <Stack gap={1}>
            <TaskTypeBlock
                taskContext={ TaskContext.JoinChannel }
                name='Подписаться на канал'
                description={ getJoinChannelDescription() }
            />

            <TaskTypeBlock
                taskContext={ TaskContext.VkShare }
                name='Разместить пост в ВК'
                description={ getVkShareDescription() }
            />

            <TaskTypeBlock
                taskContext={ TaskContext.Invite }
                name='Пригласить друзей'
                description={ friendsCount !== undefined ? `Приглашено ${ friendsCount } ${ trueFriendsForm(friendsCount)}` : ''}
            />
        </Stack>
    );
}