export const trueFriendsForm = (num: number) => trueWordForm(num, ['друг', 'друга', 'друзей']);

export const trueDaysForm = (num: number) => trueWordForm(num, ['день', 'дня', 'дней']);
export const trueMinutesForm2 = (num: number) => trueWordForm(num, ['минуту', 'минуты', 'минут']);
export const trueSecondsForm2 = (num: number) => trueWordForm(num, ['секунду', 'секунды', 'секунд']);
export const trueHoursForm = (num: number) => trueWordForm(num, ['час', 'часа', 'часов']);

const trueWordForm = (num: number, forms: [string, string, string]): string => {
    return _trueWordform(num, forms[0], forms[1], forms[2]);
}

const _trueWordform = (num: number, form_for_1: string, form_for_2: string, form_for_5: string): string => {
    num = Math.abs(num) % 100;
    var num_10: number = num % 10;
    
    if (num > 10 && num < 20) // если число принадлежит отрезку [11;19]
        return form_for_5;
    if (num_10 > 1 && num_10 < 5) // иначе если число оканчивается на 2,3,4
        return form_for_2;
    if (num_10 === 1) // иначе если оканчивается на 1
        return form_for_1;
        
    return form_for_5;
}
