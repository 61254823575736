import { Grid, Box, Typography, useTheme, Button, Container } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { vk } from 'vanilla-sharing';
import { useDispatch, useSelector } from "../../../../hooks";
import { VkshareTaskDto } from "./VkShareTask";
import { now } from "../../../../utils/time";
import { performVkShareTask } from "../../../../redux/tasks/perform-vk-share.handler";

interface IVkShareItemProps {
    task: VkshareTaskDto;
}

enum TaskState {
    Share = 'Поделиться',
    Completed = 'Выполнено'
}

export const VkShareItem: FC<IVkShareItemProps> = ({ task }) => {
    const getStageByDate = () => now() > task.date ? TaskState.Share : TaskState.Completed;
    const [stage, setStage] = useState<TaskState>(getStageByDate())

    const [refLink, setRefLink] = useState('');
    const theme = useTheme();
    const userId = useSelector(store => store.user.info.id);
    const dispatch = useDispatch();

    useEffect(() => {
        const refLink = `https://t.me/net_game_bot?start=ref${userId}`;
        setRefLink(refLink);
    }, [userId]);

    useEffect(() => {
        setStage(getStageByDate());
    }, [task.date, getStageByDate]);    

    const handleShareClick = async () => {
        vk({
            url: task.link === '' ? refLink : task.link,
            title: task.title,
            description: task.description,
            image: task.image,
            isVkParse: false,
        });

        dispatch(performVkShareTask(task.taskId));
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Container
                style={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    padding: 0
                }}
                maxWidth={false}
            >
                <img 
                    src={ task.image }
                    alt="logo"
                    style={{ 
                        width: '100%', 
                        height: '100%', 
                        objectFit: 'cover',
                        borderRadius: '10pt',
                    }} 
                />
            </Container>

            <Typography
                sx={{
                    color: theme.palette.primary.main,
                    padding: '4pt',
                    marginBottom: '4pt',
                    fontSize: '11pt',
                }}
            >
                { task.title }
            </Typography>

            <Typography
                sx={{
                    color: theme.palette.primary.main,
                    padding: '4pt',
                    marginBottom: '4pt',
                    fontSize: '10pt',
                }}
            >
                { task.description }
            </Typography>

            <Grid container>
                <Grid item xs={6}>
                    {
                        stage === TaskState.Share &&
                        <>
                            <Button variant="contained" color="primary" onClick={ handleShareClick } id='share-button'>
                                { TaskState.Share.toUpperCase() }
                            </Button>

                            <div id='vk_like' />
                        </>
                    }

                    {
                        stage === TaskState.Completed &&
                        <div
                            style={{
                                width: '100%',
                                fontSize: '12pt',
                                textAlign: 'center',
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                marginLeft: 'auto',
                                padding: '4pt'
                            }}
                        >
                                { TaskState.Completed.toUpperCase() }
                        </div>
                    }
                </Grid>                
                <Grid item xs={6}
                    sx={{
                        textAlign: 'right',
                        color: theme.palette.primary.main,
                    }}
                >
                    <Typography>{`${ task.reward } РУБЛ`}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
}