import { useNavigate as navigateHook } from "react-router-dom";
import { AppContext } from "../components/BottomMenu/BottomMenu";
import { TaskContext } from "../pages/TaskPage/TasksTypes";

export const useNavigate = () => {
    const navigate = navigateHook();
  
    const customNavigate = (context: AppContext, options?: { replace?: boolean }) => {
        const path = context === AppContext.None ? '/' : `/${context}`;
        navigate(path, options);
    };
  
    return customNavigate;
};

export const useTaskNavigate = () => {
    const navigate = navigateHook();
  
    const customNavigate = (context: TaskContext, options?: { replace?: boolean }) => {
        const path = `${context}`;
        navigate(path, options);
    };
  
    return customNavigate;
};