import { Stack, Box, useTheme, Container, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "../../../../hooks";

export const ReferalLinkBlock = () => {
    const [refLink, setRefLink] = useState('');
    const theme = useTheme();
    const userId = useSelector(store => store.user.info.id);

    useEffect(() => {
        const refLink = `https://t.me/net_game_bot?start=ref${userId}`;
        setRefLink(refLink);
    }, [userId]);

    const shareClickHandler = () => {
        const message = encodeURI(`Присоединяйся ко мне в игре Бизнесляндия\n${refLink}`);
        const url = `https://t.me/share/url?url=${message}`
        window.open(url);
    }

    return (
        <Stack
            direction='column'
            sx={{
                marginBottom: '6pt',
            }}
        >
            <Box
                sx={{
                    border: '1px solid',
                    padding: '4pt',
                    textAlign: 'center',
                    color: theme.palette.primary.main,
                    fontSize: '10pt',
                }}
            >{`Ваша реферальная ссылка: ${refLink}`}</Box>

            <Container
                sx={{
                    paddingTop: '4pt',
                    backgroundColor: theme.palette.background.paper,
                    position: 'sticky',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 'auto',
                    gap: '4pt'
                }}
            >
                <Button
                    sx={{
                        fontSize: '9pt',
                        flexBasis: '50%',
                    }}
                    variant='outlined'
                    onClick={ () => navigator.clipboard.writeText(refLink) }
                >Скопировать ссылку</Button>

                <Button sx={{ fontSize: '9pt', flexBasis: '50%' }} variant='outlined'
                    onClick={ shareClickHandler }
                >Пригласить друга</Button>
            </Container>

        </Stack>
    );
}