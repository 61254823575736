import { MainPage } from '../../pages/MainPage/MainPage';
import { BusinessPage } from '../../pages/BusinessPage/BusinessPage';
import { TasksPage } from '../../pages/TaskPage/TasksPage';
import { DrawPage } from '../../pages/DrawPage/DrawPage';
import { Route, Routes } from 'react-router-dom';
import { LogoPage } from '../../pages/LogoPage/LogoPage';

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={ <LogoPage /> } />
            <Route path='/main' element={ <MainPage /> } />
            <Route path='/business' element={ <BusinessPage /> } />                        
            <Route path='/tasks/*' element={ <TasksPage /> } />                      
            <Route path='/draw' element={ <DrawPage /> } />                        
        </Routes>
    );
}