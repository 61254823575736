import { ActionReducerMapBuilder, createSlice, Draft } from "@reduxjs/toolkit";
import { JoinChannelTaskDto } from "../../pages/TaskPage/Tasks/JoinChannel/JoinChannelTask";
import { IRequestState } from "../request.state.interface";
import { VkshareTaskDto } from "../../pages/TaskPage/Tasks/VkShareTask/VkShareTask";
import getTasksHandler from "./get-tasks.handler";
import checkJoinTasksHandler from "./check-join-channel.handler";
import performVkShareTaskHandler from "./perform-vk-share.handler";

export interface ITasksState extends IRequestState {
    joinChannelTasks: JoinChannelTaskDto[];
    vkShareTasks: VkshareTaskDto[],
};

const initialState: ITasksState = {
    joinChannelTasks: [],
    vkShareTasks: [],

    loading: false,
    error: "",
};

const tasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        tasksClearError: (state: Draft<ITasksState>) => {
            state.error = "";
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<ITasksState>) => {
        getTasksHandler(builder);
        checkJoinTasksHandler(builder);
        performVkShareTaskHandler(builder)
    },
});

export const { tasksClearError } = tasksSlice.actions;
export default tasksSlice.reducer;