import { Stack, Typography, Button, useTheme, CardMedia } from "@mui/material";
import { FC, useState } from "react";
import { JoinChannelTaskDto } from "./JoinChannelTask";
import { useDispatch, useSelector } from "../../../../hooks";
import { checkJoinChannelsTasks } from "../../../../redux/tasks/check-join-channel.handler"

interface IJoinChannelItemProps {
    task: JoinChannelTaskDto;
}

enum TaskState {
    Join = 'Перейти',
    Check = 'Проверить',
    Completed = 'Выполнено'
}

export const JoinChannelItem: FC<IJoinChannelItemProps> = ({ task }) => {
    const [stage, setStage] = useState<TaskState>(task.subscribed ? TaskState.Completed : TaskState.Join)
    
    const theme = useTheme();
    const dispatch = useDispatch();

    const { loading } = useSelector(store => store.tasks);

    const handler = () => {
        if (stage === TaskState.Join) {
            window.location.href = task.link;
            setStage(TaskState.Check);
            return;
        }
        else if (stage === TaskState.Check) {
            dispatch(checkJoinChannelsTasks(task.taskId));
            setStage(TaskState.Join);
            return;
        }
    }

    return (
        <>
            <Stack
                direction="column"
                sx={{
                    border: '1px solid',
                    borderRadius: '3pt',
                    padding: '3pt 3pt 3pt 0pt',
                    color: theme.palette.primary.main,
                    height: '95pt',
                }}
            >
                <Stack
                    direction="row"
                    spacing={0}
                >
                    <CardMedia
                        component="img"
                        image={ task.image }
                        alt="Лого канала"
                        sx={{
                            width: '45pt',
                            height: '45pt',
                            objectFit: 'cover',
                            borderRadius: '50%',
                            paddingLeft: '3pt',
                            paddingRight: '4pt',
                            marginBottom: '-3pt',
                        }}
                    >
                    </CardMedia>

                    <Typography sx={{ width: '100%' }} >
                        { task.title }
                    </Typography>

                    <Typography
                        sx={{
                            width: '100pt',
                            textAlign: 'right',
                        }}
                    >
                        { `${task.reward} РУБЛ` }
                    </Typography>
                </Stack>
                
                <div
                    style={{
                        width: '100%',
                        height: '240pt',
                        fontSize: '10pt',
                        textAlign: 'left',
                        margin: '6pt',
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        marginLeft: 'auto',
                        padding: '2pt'
                    }}
                >
                        { task.description }
                </div>

                {
                    !task.subscribed && 
                    <Button
                        sx={{
                            height: '20pt',
                            fontSize: '9pt',
                            width: '200pt',
                            margin: '0pt auto 10pt auto',
                        }}
                        variant={ 'outlined' }
                        onClick={ () => handler() }
                    >
                        { loading ? '' : stage.toUpperCase() }
                    </Button>
                }

                {
                    task.subscribed &&
                    <div
                        style={{
                            width: '100%',
                            fontSize: '10pt',
                            textAlign: 'center',
                            margin: '6pt',
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            marginLeft: 'auto',
                            padding: '2pt'
                        }}
                    >
                            { TaskState.Completed.toUpperCase() }
                    </div>
                }
            </Stack>
        </>
    );
}