import { Container } from "@mui/material";
import { useEffect } from "react";
import { useTelegram } from "../../hooks/telegram";
import { useDispatch, useSelector } from "../../hooks";
import { login } from "../../redux/user/user-info.slice";
import { setContext } from "../../redux/context.slice";
import { AppContext } from "../../components/BottomMenu/BottomMenu";
import { useNavigate } from "../../hooks/navigate";

export const LogoPage = () => {
    const telegram = useTelegram();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector(store => store.user.info);

    useEffect(() => {
        if (!telegram)
            return;
        
        dispatch(login(telegram.initData));
    }, [dispatch, telegram]);

    useEffect(() => {
        if (!userInfo.id)
            return;
        
        setTimeout(() => {
            dispatch(setContext(AppContext.Main));
            navigate(AppContext.Main);
        }, 2000);
    }, [dispatch, navigate, userInfo]);
    
    return (
        <Container 
            style={{
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                height: '100vh',
                padding: 0
            }}
            maxWidth={false}
        >
            <img 
                src="/net-game.logo.jpg"
                alt="logo"
                style={{ 
                    width: '100%', 
                    height: '100%', 
                    objectFit: 'cover'
                }} 
            />
        </Container>
    );
}