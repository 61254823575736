import { FC } from "react";
import { Typography, useTheme } from '@mui/material';

export interface IJobHeaderProps {
    jobName: string;
}

export const JobHeader: FC<IJobHeaderProps> = ({ jobName }) => {
  const theme = useTheme();

  return (
        <Typography
            variant="body1"
            component="div"
            style={{
                border: '1px solid',
                borderRadius: '5pt',
                color: theme.palette.primary.main,
                fontWeight: 'bold',
                textAlign: 'center',
                padding: theme.spacing(1),
            }}
            >
            { jobName }
        </Typography>
    );
};