import { useEffect, useState } from "react";

export const useTelegram = () => {
    const [tg, setTg] = useState<any>(null);
  
    useEffect(() => {
        const telegramWebApp = (window as any).Telegram?.WebApp;
        if (telegramWebApp) {
            setTg(telegramWebApp);
        }
    }, []);
  
    return tg;
};