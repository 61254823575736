import { Typography, useTheme } from "@mui/material";
import { getTimeLeft, now, unixToString } from "../../utils/time";

export const DrawCountdown = () => {
    const theme = useTheme();

    const time2 = now() + 30000;
    const time = time2 - time2 % 3600;

    return (
        <Typography
            sx={{
                marginTop: '20pt',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                border: '1px solid',
                marginBottom: '5pt',
                textAlign: 'center',
                padding: theme.spacing(1),
            }}
        >
            <div>
                { `Время следующего розыгрыша:` }
            </div>
            <div>
                { `${unixToString(time)} (осталось ${getTimeLeft(time, -1)})` }
            </div>
        </Typography>
    );
}