import { Grid, useTheme } from "@mui/material";
import { GameLevel } from "../GameLevel/GameLevel";
import { PassiveIncome } from "../PassiveIncome/PassiveIncome";
import { BalanceDisplay } from "../BalanceDisplay/BalanceDisplay";

export const GeneralInfo = () => {
    const theme = useTheme();

    return (
        <div
            style={{
                marginBottom: '10pt',
            }}
        >
            <div
                style={{
                    backgroundColor: theme.palette.background.paper,
                    bottom: 0,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Grid container spacing={2} sx={{ padding: 0 }}>
                    <Grid item xs={6} sx={{ padding: 0 }}>
                        <GameLevel />
                    </Grid>
                    <Grid item xs={6} sx={{ padding: 0 }}>
                        <PassiveIncome />
                    </Grid>
                </Grid>
            </div>

            <BalanceDisplay />
        </div>
    );
}