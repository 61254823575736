import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/index';
import { App } from './components/App/App';
import { BrowserRouter as Router } from 'react-router-dom';
import { DesktopPage } from './pages/DesktopPage/DesktopPage';

const tg = (window as any).Telegram.WebApp;
tg.expand();

const container = document.getElementById('root');

if (container) {
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <Provider store={ store }>
                <Router>
                    { tg.initData || process.env.REACT_APP_ENV === 'DEV' ? <App /> : <DesktopPage /> }
                </Router>
            </Provider>
        </React.StrictMode>
    );
}