import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "../../api/api";
import { IDrawsState } from ".";
import { IDraw } from "../../pages/DrawPage/DrawItem";

export interface IGetDrawsResponse {
    draws: IDraw[],    
};

export const getDraws = createAsyncThunk<IGetDrawsResponse>(
    'getDraws',
    async () => await api.getDraws()
);;

const getDrawsHandler = (builder: ActionReducerMapBuilder<IDrawsState>) => {
    builder
        .addCase(getDraws.pending, (state: IDrawsState) => {
            state.loading = true;
            state.error = "";
        })
        .addCase(getDraws.fulfilled, (state: IDrawsState, action: PayloadAction<IGetDrawsResponse>) => {
            state.loading = false;
            state.items = action.payload.draws;
        })
        .addCase(getDraws.rejected, (state: IDrawsState) => {
            state.loading = false;
            state.error = 'Unable to get draws';
        });
};

export default getDrawsHandler;