import { Stack } from "@mui/material";
import { IReferal, ReferalItem } from "./ReferalItem";
import { useEffect } from "react";
import { useDispatch, useSelector } from "../../../../hooks";
import { getReferals } from "../../../../redux/user/user-referals.slice";
import { Loader } from "../../../../components/Loader/Loader";

export const ReferalsList = () => {    
    const dispatch = useDispatch();
    const { items, loading } = useSelector(store => store.user.referals);
    
    useEffect(() => {
        if (items)
            return;

        dispatch(getReferals());
    }, [items, dispatch]);

    return (
        <>
            <Stack
                direction='column'
            >
                {
                    items && items.map((referal: IReferal, index: number) => {
                        return (
                            <ReferalItem
                                key={ `referal${index}` }
                                referalData={ referal }
                            />
                        );
                    })
                }
            </Stack>
            
            { loading && <Loader /> }
        </>
    );
}