import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ITasksState } from '.';
import { api } from '../../api/api';
import { JoinChannelTaskDto } from '../../pages/TaskPage/Tasks/JoinChannel/JoinChannelTask';
import { VkshareTaskDto } from '../../pages/TaskPage/Tasks/VkShareTask/VkShareTask';

export interface IGetTasksResponse {
    joinChannelTasks: JoinChannelTaskDto[],
    vkShareTasks: VkshareTaskDto[],
};

export const getTasks = createAsyncThunk<IGetTasksResponse>(
    'getTasks',
    async () => await api.getTasks()
);;

const getTasksHandler = (builder: ActionReducerMapBuilder<ITasksState>) => {
    builder
        .addCase(getTasks.pending, (state: ITasksState) => {
            state.loading = true;
            state.error = "";
        })
        .addCase(getTasks.fulfilled, (state: ITasksState, action: PayloadAction<IGetTasksResponse>) => {
            state.loading = false;
            state.joinChannelTasks = action.payload.joinChannelTasks;
            state.vkShareTasks = action.payload.vkShareTasks;
        })
        .addCase(getTasks.rejected, (state: ITasksState) => {
            state.loading = false;
            state.error = 'Unable to get tasks';
        });
};

export default getTasksHandler;