import { Button } from "@mui/material";
import { FC } from "react";

interface IButtonBlockProps {
    caption: string;
    handler: () => void;
}

export const ButtonBlock: FC<IButtonBlockProps> = ({ caption, handler }) => {
    return (
        <div
            style={{
                textAlign: 'center'
            }}
        >
            <Button
                sx={{
                    height: '40pt',
                    width: '100%'
                }}
                variant={ 'outlined' }
                onClick={ handler }
            >
                { caption }
            </Button>
        </div>
    );
}