import { Stack } from "@mui/material";
import { JoinChannelItem } from "./JoinChannelItem";
import { useSelector } from "../../../../hooks";

export class JoinChannelTaskDto {
    taskId!: number;
    title!: string;
    link!: string;
    description!: string;
    image!: string;
    reward!: number;
    subscribed!: boolean;
}

export const JoinChannelTask = () => {
    const joinChannelTasks = useSelector(store => store.tasks.joinChannelTasks);
    
    return (
        <Stack gap={ 1 } direction='column'>
            {
                joinChannelTasks && joinChannelTasks.map(task => {
                    return (
                        <JoinChannelItem
                            key={ `joinChannelTask${task.link}` }
                            task={ task }
                        />
                    );
                })
            }
        </Stack>
    );
}