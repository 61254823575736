import { FC } from "react";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "@reduxjs/toolkit";
import { RootState } from "..";

export const SET_MODAL = 'SET_MODAL';

export interface ISetModalAction<T> {
    readonly type: typeof SET_MODAL;
    modalComponent: FC;
    props: T;
};

export const setModalAction = <T>(modalComponent: FC, props: T): ISetModalAction<T> => ({ type: SET_MODAL, modalComponent, props });

export const setModal = (modalComponent: FC | null) => (dispatch: ThunkDispatch<RootState, void, Action>) => {
    if (modalComponent)
        dispatch(setModalAction(modalComponent, null));
}
