import { Button } from "@mui/material";
import { FC } from "react";
import { setContext } from "../../redux/context.slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "../../hooks/navigate";
import { IBottomButton } from "./BottomMenu";
import { useSelector } from "../../hooks";

export const BottomMenuButton: FC<IBottomButton> = ({ context, caption }) => {
    const navigate = useNavigate();
    const dispath = useDispatch();
    const currentContext = useSelector(store => store.context.currentContext);

    const handler = () => {
        navigate(context);
        dispath(setContext(context));
    }
    
    return (
        <Button
            sx={{
                flexBasis: '25%',
                height: '40pt'
            }}
            variant={ currentContext === context ? 'contained' : 'outlined' }
            onClick={ handler }
        >
            { caption }
        </Button>
    );
}