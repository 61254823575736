import axios, { AxiosInstance } from "axios";

export class Api {
    private readonly m_baseURL: string;
    private m_axiosInstance: AxiosInstance;

    constructor() {
        this.m_baseURL = process.env.REACT_APP_BACKEND_URL!;

        this.m_axiosInstance = axios.create({
            baseURL: this.m_baseURL,
            withCredentials: true
        });
    }

    private async get(endpoint: string) {
        try {
            return (await this.m_axiosInstance.get(endpoint)).data;
        }
        catch (error: any) {
            this.relogin(error);
        }
    }
  
    private async post(endpoint: string, body: object) {
        try {
            return (await this.m_axiosInstance.post(endpoint, body)).data;
        }
        catch (error: any) {
            this.relogin(error);
        }
    }

    private relogin(error: any) {
        if (error.response.status !== 403)
            return;
        
        window.location.href = '/';
        throw error;
    }

    public async login(initData: string) {
        if (initData === '')
            initData = process.env.REACT_APP_TEMP_INIT_DATA!;

        return this.post(`/auth/login`, { initData });
    }

    public async getReferals() {
        return this.get(`/users/referals`);
    }

    public async getTasks() {
        return this.get('/tasks/user-tasks');
    }

    public async checkJoinChannelTasks(taskId: number) {
        return this.get(`/tasks/check-join-channel?taskId=${taskId}`);
    }

    public async performVkShareTask(taskId: number) {
        return this.post(`/tasks/perform-vk-share`, { taskId });
    }

    public async getDraws() {
        return this.get('/draws');
    }

    public async buyDrawTicket(drawId: number) {
        return this.post('/draws/buy-ticket', { drawId });
    }
}

export const api = new Api();