import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "../../api/api";
import { IDrawsState } from ".";
import { DrawMemberStatus } from "../../pages/DrawPage/DrawItem";

export interface IBuyTicketResponse {
    balance: number;
    drawId: number;
    memberStatus: DrawMemberStatus;
};

export const buyDrawTicket = createAsyncThunk<IBuyTicketResponse, number>(
    'buyDrawTicket',
    async (drawId: number) => await api.buyDrawTicket(drawId)
);;

const buyDrawTicketHandler = (builder: ActionReducerMapBuilder<IDrawsState>) => {
    builder
        .addCase(buyDrawTicket.pending, (state: IDrawsState) => {
            state.loading = true;
            state.error = "";
        })
        .addCase(buyDrawTicket.fulfilled, (state: IDrawsState, action: PayloadAction<IBuyTicketResponse>) => {
            state.loading = false;

            const draw = state.items.find(draw => draw.drawId === action.payload.drawId);
            if (!draw) {
                state.error = 'Unable to buy draw ticket';
                return;
            }

            draw.userStatus = action.payload.memberStatus;
        })
        .addCase(buyDrawTicket.rejected, (state: IDrawsState) => {
            state.loading = false;
            state.error = 'Unable to buy draw ticket';
        });
};

export default buyDrawTicketHandler;