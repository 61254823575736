import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api/api";
import { IRequestState } from "../request.state.interface";
import { IReferal } from "../../pages/TaskPage/Tasks/InviteTask/ReferalItem";

export interface IUserReferalsState extends IRequestState {
    items: IReferal[] | null;
};

const initialState: IUserReferalsState = {
    items: null,

    loading: false,
    error: '',
};

type ReferalsResponse = { referals: IReferal[] };

export const getReferals = createAsyncThunk<ReferalsResponse>(
    'userReferals',
    async () => {
        const data = await api.getReferals();
        return data;
    }
);

const userReferalsSlice = createSlice({
    name: 'userReferals',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getReferals.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(getReferals.fulfilled, (state, action: PayloadAction<ReferalsResponse>) => {
                state.loading = false;

                state.items = action.payload.referals;
            })
            .addCase(getReferals.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Unable to get referals list';
            });
    },
});

export default userReferalsSlice.reducer;