import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IUserInfo } from "../../model/user-info.interface";
import { api } from "../../api/api";
import { IRequestState } from "../request.state.interface";

export interface IUserInfoState extends IUserInfo, IRequestState {};

const initialState: IUserInfoState = {
    id: 0,
    level: 0,
    balance: 0,
    profit: 0,

    loading: false,
    error: "",
};

type LoginResponse = { user: IUserInfo };

export const login = createAsyncThunk<LoginResponse, string>(
    'userInfo',
    async (initData: string) => {
        const data = await api.login(initData);
        return data;
    }
);

const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
                state.error = "";
            })
            .addCase(login.fulfilled, (state, action: PayloadAction<LoginResponse>) => {
                state.loading = false;

                state.id = action.payload.user.id;
                state.level = action.payload.user.level;
                state.balance = action.payload.user.balance;
                state.profit = action.payload.user.profit;
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Unable to login';
            });
    },
});

export default userInfoSlice.reducer;