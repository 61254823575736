import { GeneralInfo } from "../../components/GeneralInfo/GeneralInfo";
import { JobHeader } from "../../components/JobHeader/JobHeader";

export const MainPage = () => {
    return (
        <>
            <GeneralInfo />

            <JobHeader  jobName={ 'Работа не бей лежачего' } />
        </>
    );
}