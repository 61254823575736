import { PayloadAction, ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ITasksState } from '.';
import { api } from '../../api/api';

export interface IPerformVkShareResponse {
    taskId: number;
    date: number;
};

export const performVkShareTask = createAsyncThunk<IPerformVkShareResponse, number>(
    'performVkShareTask',
    async (taskId: number) => await api.performVkShareTask(taskId)
);

const performVkShareTaskHandler = (builder: ActionReducerMapBuilder<ITasksState>) => {
    builder
        .addCase(performVkShareTask.pending, (state: ITasksState) => {
            state.loading = true;
            state.error = "";
        })
        .addCase(performVkShareTask.fulfilled, (state: ITasksState, action: PayloadAction<IPerformVkShareResponse>) => {
            state.loading = false;

            if (!action.payload) {
                state.error = 'Unable to perform vk share task';
                return;
            }

            state.vkShareTasks.forEach(task => {
                if (task.taskId === action.payload.taskId) {
                    task.date = action.payload.date;
                }
            });
        })
        .addCase(performVkShareTask.rejected, (state: ITasksState) => {
            state.loading = false;
            state.error = 'Unable to perform vk share task';
        });
};

export default performVkShareTaskHandler;