import { Typography, useTheme } from '@mui/material';
import { useSelector } from "../../hooks";

export const BalanceDisplay = () => {
    const theme = useTheme();
    const balance = useSelector(store => store.user.info.balance);

    return (
            <Typography
                variant="h5"
                component="div"
                style={{
                    border: '1px solid',
                    borderRadius: '5pt',
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    padding: theme.spacing(1),
                    marginBottom: '5pt'
                }}
            >
                {balance.toFixed(0)} РУБЛ
            </Typography>
        );
};