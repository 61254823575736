import { ActionCreator, combineReducers, compose } from 'redux';
import { ThunkAction, thunk } from 'redux-thunk';
import { modalReducer } from './reducers/set-modal';
import { configureStore } from '@reduxjs/toolkit';
import contextReducer from './context.slice';
import userInfoReducer from './user/user-info.slice';
import userReferalsReducer from './user/user-referals.slice';
import tasksReducer from './tasks/';
import drawsReducer from './draws/';

export const rootReducer = combineReducers({
    context: contextReducer,
    modal: modalReducer,

    user: combineReducers({
        info: userInfoReducer,
        referals: userReferalsReducer,
    }),

    tasks: tasksReducer,
    draws: drawsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
    devTools: process.env.ENV !== 'PROD',
});

export type TStore = ReturnType<typeof rootReducer>;

type TApplicationActions = any;

export type AppThunk<TReturn = void> = ActionCreator<ThunkAction<TReturn, TStore, unknown, TApplicationActions> >;

export type AppDispatch = typeof store.dispatch;