import { Button, Grid, Typography, useTheme } from "@mui/material";
import { GeneralInfo } from "../../components/GeneralInfo/GeneralInfo";

export const BusinessPage = () => {
    const theme = useTheme();
    
    return (
        <>
            <GeneralInfo />

            <div
                style={{
                    backgroundColor: theme.palette.background.paper,
                    bottom: 0,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Grid container spacing={2} sx={{ padding: 0 }}>
                    <Grid item xs={6} sx={{ padding: 0 }}>
                        <Typography
                            variant="body1"
                            component="div"
                            style={{
                                border: '1px solid',
                                borderRadius: '5pt',
                                color: theme.palette.primary.main,
                                fontWeight: 'bold',
                                textAlign: 'center',
                                padding: theme.spacing(1),
                            }}
                            >
                            { `Бизнесов: 0` }
                        </Typography>
                    </Grid>

                    <Grid item xs={6} sx={{ padding: 0 }}>
                        <Button
                            sx={{
                                flexBasis: '25%',
                                height: '31pt'
                            }}
                            variant="outlined"
                        >
                            Начать новый
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}