import { Container, useTheme } from '@mui/material';
import { AppContext, BottomMenu } from '../BottomMenu/BottomMenu';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import { useSelector } from '../../hooks';
import { useEffect } from 'react';
import { useNavigate } from '../../hooks/navigate';

export const App = () => {
    const theme = useTheme();
    const location = useLocation();
    const user = useSelector(store => store.user.info);
    const navigate = useNavigate();
    
    const isLogoPage = location.pathname === '/';

    useEffect(() => {
        if (user.id || isLogoPage)
            return;

        navigate(AppContext.None);
    }, [user, isLogoPage, navigate]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Container maxWidth="lg"
                style={{
                    flexGrow: 1,
                    padding: isLogoPage ? 0 : '24px',
                    overflow: 'auto',
                    backgroundColor: theme.palette.background.paper,
                }}
            >
                <AppRoutes />
            </Container>

            { !isLogoPage && <BottomMenu /> }            
        </div>
    );
};
