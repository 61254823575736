import { Button } from "@mui/material";
import { FC } from "react";

interface IBuyDrawTicketBlockProps {
    handler: () => void;    
}

export const BuyDrawTicketBlock: FC<IBuyDrawTicketBlockProps> = ({ handler }) => {
    return (
        <div
            style={{
                textAlign: 'center'
            }}
        >
            <Button
                sx={{
                    height: '30pt',
                    width: 'auto',
                    margin: '5pt',
                }}

                variant={ 'outlined' }
                onClick={ handler }
            >
                'Купить пропуск'
            </Button>
        </div>
    );
}