import { Stack, Typography, useTheme } from "@mui/material";
import { VkShareItem } from './VkShareItem';
import { useSelector } from "../../../../hooks";

export class VkshareTaskDto {
    taskId!: number;
    title!: string;
    link!: string;
    description!: string;
    image!: string;
    reward!: number;
    date!: number;
    cooldown!: number;
}

export const VkShareTask = () => {
    const theme = useTheme();
    const vkShareTasks = useSelector(store => store.tasks.vkShareTasks)

    return (
        <Stack direction='column'>
            <Typography
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    padding: '4pt',
                    marginBottom: '4pt',
                    fontSize: '10pt',
                }}
            >
                Описание задания с указанием количества РУБЛ, получаемых за пост 
            </Typography>

            <Stack gap={ 1 } direction='column'>
            {
                vkShareTasks && vkShareTasks.map(task => {
                    return (
                        <VkShareItem
                            key={ `vkShareTask${task.link}` }
                            task={ task }
                        />
                    );
                })
            }
            </Stack>
        </Stack>
    );
}