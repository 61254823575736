import { Image } from "@mui/icons-material";
import { Typography, Stack, useTheme } from "@mui/material";
import { FC } from "react";
import { TaskContext } from "./TasksTypes";
import { useTaskNavigate } from "../../hooks/navigate";

export interface ITaskTypeBlockProps {
    taskContext: TaskContext;
    name: string;
    description: string;
}

export const TaskTypeBlock: FC<ITaskTypeBlockProps> = ({ taskContext, name, description }) => {
    const theme = useTheme();
    const taskNavigate = useTaskNavigate();

    return (
        <Stack
            direction="row"
            spacing={0}
            sx={{
                border: '1px solid',
                borderRadius: '2pt',
                padding: '3pt',
                color: theme.palette.primary.main,
                height: '25pt',
                cursor: 'pointer',
            }}
            onClick={ () => taskNavigate(taskContext) }
        >
            <Image>

            </Image>

            <Typography
                sx={{
                    width: '100%'
                }}
            >
                { name }
            </Typography>

            <Typography
                sx={{
                    width: '80pt',
                    fontSize: '7pt',
                    textAlign: 'right',
                }}>
                { description }
            </Typography>
        </Stack>
    );
}