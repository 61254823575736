import { Container, useTheme, } from '@mui/material';
import { BottomMenuButton } from './BottomMenuButton';

export enum AppContext {
    None = 'none',
    Main = 'main',
    Business = 'business',
    Tasks = 'tasks',
    Draw = 'draw'
}

export interface IBottomButton {
    context: AppContext;
    caption: string;
}

export const BottomMenu = () => {
    const theme = useTheme();

    return (
        <Container
            sx={{
                padding: '8pt',
                backgroundColor: theme.palette.background.paper,
                position: 'sticky',
                bottom: 0,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                margin: 'auto',
                gap: '4pt'
            }}
        >
            <BottomMenuButton caption='Работа' context={ AppContext.Main } />
            <BottomMenuButton caption='Бизнес' context={ AppContext.Business } />
            <BottomMenuButton caption='Задания' context={ AppContext.Tasks } />
            <BottomMenuButton caption='Подарки' context={ AppContext.Draw } />
        </Container>
    );
};
