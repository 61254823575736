import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppContext } from '../components/BottomMenu/BottomMenu';

export interface IContextState {
    currentContext: AppContext;
}

const initialState: IContextState = {
    currentContext: AppContext.None,
};

const contextSlice = createSlice({
    name: 'context',
    initialState,
    reducers: {
        setContext: (state: IContextState, action: PayloadAction<AppContext>) => {
            state.currentContext = action.payload;
        },
    },
});

export const { setContext } = contextSlice.actions;
export default contextSlice.reducer;